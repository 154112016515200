<template>
<!-- Header Start -->
<div class="row sticky-top">
    <nav class="navbar navbar-expand-md bg-dark navbar-dark">
        <div class="container">
            <a @click.prevent="homePage" href="" class="navbar-brand">
                <img src="../../public/assets/images/ColorMedia.png" alt="Logo" class="" style="width: 120px;">
            </a>
            <button class="navbar-toggler" data-toggle="collapse" data-target="#menu">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="menu">
                <ul class="navbar-nav ms-auto text-center text-uppercase">
                    <li class="nav-item">
                        <router-link :to="{name: 'Home'}"
                            class="nav-link">Home</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{name: 'About'}"
                            class="nav-link">About</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{name: 'Service'}"
                            class="nav-link">Service</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{name: 'Portfolio'}"
                            class="nav-link">Portfolio</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{name:'Partners'}"
                            class="nav-link">Partners</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{name:'Contact'}"
                            class="nav-link">Contact</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>
<!-- Header Start -->


</template>
<script>
export default({
    data(){
        return{

        }
    },
    methods:{
        homePage(){
            this.$router.push('/')
        }
    }

})
</script>