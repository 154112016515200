// import Vue from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
// import HelloWorld from '../components/HelloWorld'

const routes = [
   // { name: 'Home', path: '/home', component: HelloWorld }
   { name: 'Home', path: '/', component: () => import('../pages/HomePage.vue') },
   { name: 'About', path: '/about', component: () => import('../pages/AboutPage.vue') },
   { name: 'Service', path: '/service', component: () => import('../pages/ServicePage.vue') },
   { name: 'Portfolio', path: '/portfolio', component: () => import('../pages/PortfolioPage.vue') },
   { name: 'Partners', path: '/partners', component: () => import('../pages/PartnersPage.vue') },
   { name: 'Contact', path: '/contact', component: () => import('../pages/ContactPage.vue') },
];

const router = createRouter({
   history: createWebHistory(),
   routes,
   linkActiveClass: "active",
})

export default router;