<template>
  <section class="footer bg-dark text-light" style="padding: 40px 0px 10px">
    <div class="container mb-3">
        <div class="row">
            <div class="col-md-4 pb-3 text-center">
                <h5 class="text-primary">Address:</h5>
                <address class="mt-3">
                    House: 44, Road No: 12, Lane: 2, Suit: A-1,<br>
                    Section: 11, Block: B, Mirpur, Pallabi,<br>
                    Dhaka 1216
                </address>
            </div>
            <div class="col-md-4 pb-3 text-center">
                <h5 class="text-primary">Important Links:</h5>
                <div class="d-flex flex-column">
                    <a class="text-decoration-none text-light" href="">Career</a>
                    <a class="text-decoration-none text-light" href="">About</a>
                    <a class="text-decoration-none text-light" href="">FAQ</a>
                    <a class="text-decoration-none text-light" href="">Help</a>
                </div>
            </div>
            <div class="col-md-4 pb-3 text-center">
                <h5 class="text-primary">Others:</h5>
                <div class="d-flex flex-column">
                    <a class="text-decoration-none text-light" href="">Robi link</a>
                    <a class="text-decoration-none text-light" href="">Banglalink Link</a>
                    <a class="text-decoration-none text-light" href="">GP Link</a>
                    <a class="text-decoration-none text-light" href="">Teletalk</a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-">
                <h5 class="text-primary text-center">Find Us On Social Media</h5>
                <ul class="d-flex justify-content-center list-unstyled mx-auto">
                    <li class=""><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                    <li class=""><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                    <li class=""><a href="#"><i class="fab fa-google"></i></a></li>
                    <li class=""><a href="#"><i class="fab fa-youtube"></i></a></li>
                    <li class=""><a href="#"><i class="fab fa-twitter"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12 text-center">
            <h6 class="p-1">Color Media BD Ltd. | copyright &#169; {{ date }}</h6>
        </div>
    </div>
</section>

</template>
<script>
export default({
  data(){
    return{
      date: new Date().getFullYear()
    }
  },
  created(){
    },
  methods:{
    
  }
})
</script>