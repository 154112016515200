<template>
  <div>
    <header-view></header-view>
    <div class="main">
      <router-view></router-view>
    </div>
    <footer-view></footer-view>
  </div>
</template>

<script>
import HeaderView from './components/HeaderView.vue'
import FooterView from './components/FooterView.vue'
export default {
  name: 'App',
  components: {
    HeaderView, FooterView
  }
}
</script>

<style>
</style>
